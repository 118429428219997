import React from "react";
import Footer from "../components/footer.component";
import Page from "../components/page.component";
import Card from "../components/UI/card.component";
import Divider from "../components/UI/Divider/divider.component";
export default function AboutUs() {
  return (
    <Page noPadding="true">
      <Divider left="ჩვენს შესახებ" leftClass="text-lg" />
      <Card class="w-11/12 bg-opacity-80">
        <div className="p-20 text-left">
          <p>
            მედფოქსი ფიზიკური მედიცინისა და რეაბილიტაციის სპეციალისტების მიერ
            შექმნილი ინოვაციური სერვისია, რომელიც მიზნად ისახავს საზოგადოებას
            შესთავაზოს ნებისმიერ სივრცეში (სახლში, ოფისში, სასტუმროში ან
            კლინიკურ გარემოში) სარეაბილიტაციო მომსახურება.
          </p>
          <br />
          <p>
            თუ თქვენ კარდიო, ნეირო, პოსტ-ოპერაციული, გამაჯანსაღებელი ან სხვა
            ტიპის რეაბილიტაცია გჭირდებათ, ჩვენი მაღალკვალიფიციური სპეციალისტები
            უკვე ადგილზე მოგაკითხავენ და თქვენს გამოჯანმრთელებაზე იზრუნებენ.
          </p>
          <br />
          <p>ჩვენ გთავაზობთ:</p>
          <br />
          <ul className="list-disc ml-6">
            <li className="text-zinc-900">ორთპედიულ რეაბილიტაციას</li>
            <li className="text-zinc-900">ნეირო რეაბილიტაციას</li>
            <li className="text-zinc-900">კარდიო რეაბილიტაციას</li>
            <li className="text-zinc-900">გერიატრიულ რეაბილიტაციას</li>
            <li className="text-zinc-900">ფუნქციურ რეაბილიტაციას</li>
            <li className="text-zinc-900">პედიატრიულ რეაბილიტაციას</li>
            <li className="text-zinc-900">პოსტკოვიდურ რეაბილიტაციას</li>
            <li className="text-zinc-900">დისტანციურ სერვისებს</li>
          </ul>
        </div>
      </Card>
      <Footer />
    </Page>
  );
}
